import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "./@/components/ui/card";
import { Button } from "./@/components/ui/button";
import { Input } from "./@/components/ui/input";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Register() {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/register`, {
        username,
        firstName,
        lastName,
        email,
        password
      });
      if (response.status === 201) {
        navigate('/verify', { state: { email } });
      }
    } catch (error) {
      if (error.response && error.response.data.message === 'duplicate_email') {
        toast.error(t('register_duplicate_email'), {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.error('Registration error:', error);
      }
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center" id="g1">
      <div className="absolute top-2 right-1 m-4">
        <a href="/login" className="nav-item text-white">
          <i className="fas fa-sign-in-alt mr-2"></i>
          {t('login')}
        </a>
      </div>

      <div className="absolute top-2 left-1 m-4 px-3 py-2">
        <a href="/" className="text-xl text-white font-medium text-center">
          <i className="fas fa-chart-line mr-2"></i>
          ImproveGrade.com
        </a>
      </div>

      <div className="absolute bottom-2 left-1 m-4 px-3 py-2 w-[50%-55px]">
        <p className="text-white text-lg text-left space-y-2 hidden">"Jupiter m'a permis d'améliorer la gestion de mes
          résulats scolaires et m'a aidé à me focaliser sur mes objectifs." - <span
            className="font-bold">John Doe</span></p>
      </div>

      <div className="w-1/2 h-full flex bg-green-500 border invisible">
      </div>

      <div className="w-1/2 h-full border-none flex">
        <Card className="m-auto w-2/3 bg-secondary">
          <form onSubmit={handleSubmit}>

            <CardHeader>
              <CardTitle>{t('register_title')}</CardTitle>
            </CardHeader>

            <CardContent className="flex flex-col gap-6">
              <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required
                     className="bg-secondary"
                     placeholder={t('username_placeholder')}/>
              <Input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required
                     className="bg-secondary"
                     placeholder={t('first_name_placeholder')}/>
              <Input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required
                     className="bg-secondary"
                     placeholder={t('last_name_placeholder')}/>
              <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required
                     className="bg-secondary"
                     placeholder={t('email_placeholder')}/>
              <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required
                     className="bg-secondary"
                     placeholder={t('password_placeholder')}/>
            </CardContent>

            <CardFooter className="flex flex-col gap-2">
              <Button type="submit" className="w-full">
                <i className="fas fa-user-plus mr-2"></i>
                {t('register_button')}
              </Button>
              <p className="text-center text-gray-500 text-sm">{t('terms_conditions')}</p>
            </CardFooter>
          </form>
        </Card>
      </div>
    </div>
  );
}

export default Register;