// front/src/Dashboard.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import TableauCard from "./TableauCard";

function Dashboard({onLogout}) {
  const navigate = useNavigate();
  const userName = localStorage.getItem('userName'); // Retrieve the user's name
  const greetings = ['Ravie de vous revoir!', 'Content de vous voir!', 'Heureux de vous retrouver!'];
  const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)]; // Select a random greeting

  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/favoris`, {
          params: {
            idUtilisateur: localStorage.getItem('userId')
          }
        });
        console.log(response.data);
        localStorage.setItem('favorites', JSON.stringify(response.data));
        setFavorites(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTableaux = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/tableaux/bysUser`, {
          params: {
            idUtilisateur: localStorage.getItem('userId')
          }
        });
        console.log(response.data);
        localStorage.setItem('tableaux', JSON.stringify(response.data));
        setFavorites(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const storedFavorites = localStorage.getItem('favorites');
    const storedTableaux = localStorage.getItem('tableaux');

    if (storedFavorites) {
      setFavorites(JSON.parse(storedFavorites));
    } else {
      fetchFavorites();
    }

    if (storedTableaux) {
      setFavorites(JSON.parse(storedTableaux));
    } else {
      fetchTableaux();
    }
  }, []);

  const handleTableClick = (id) => {
    navigate('/tableau/' + id);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center relative">

      <div className="flex flex-row gap-4 w-full m-4 px-4">

        <div className="container !w-full h-20 flex flex-col items-center justify-center font-bold">
          <h1>Bonjour {userName} !</h1>
          <p className="hidden">{randomGreeting}</p>
        </div>
        <div className="container !w-full h-20">
          <button
            className="btn btn-primary hover:underline hover:transform hover:scale-95 transition"
            onClick={() => navigate('/profile')}>
            <i className="fas fa-user mr-2"></i>
            Mon profil
          </button>
        </div>
        <div className="container !w-full h-20">
          <button onClick={onLogout}
                  className="btn btn-primary hover:underline hover:transform hover:scale-95 transition">
            <i className="fas fa-sign-out-alt mr-2"></i>
            Se déconnecter
          </button>
        </div>
      </div>

      <div className="flex flex-row gap-4 w-full px-4">
        <div className="container !w-1/2 overflow-y-auto h-fit mt-0 mb-auto">
          <h2 className="font-bold w-full flex justify-between">
            Mes créations
            <i className="fas fa-chart-line mt-1 mr-1"></i>
          </h2>
          <div className="h-full overflow-y-auto flex flex-row">
            {favorites.map((favorite, index) => (
              <TableauCard className="min-w-[200px]" key={index} tableau={favorite}
                           onClick={() => handleTableClick(favorite.idtableau)}/>
            ))}
          </div>
        </div>
        <div className="container !w-1/2 overflow-y-auto h-fit mt-0 mb-auto">
          <h2 className="font-bold w-full flex justify-between">
            Mes favoris
            <i className="fas fa-star mt-1 mr-1"></i>
          </h2>
          <div className="h-full overflow-y-auto flex flex-row">
            {favorites.map((favorite, index) => (
              <TableauCard className="min-w-[200px]" key={index} tableau={favorite}
                           onClick={() => handleTableClick(favorite.idtableau)}/>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}

export default Dashboard;