import React, { useEffect, useState } from 'react';
import { Button } from "./@/components/ui/button";
import { Badge } from "./@/components/ui/badge";
import { useNavigate } from "react-router-dom";
import IncreaseToValueVariant1 from "./CuiCui/IncreaseToValueVariant1";
import { useTranslation } from 'react-i18next';
import axios from "axios";

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

function LandingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userCount, setUserCount] = useState(0);

  const handleMouseEnter = () => {
    navigate('/search');
  };

  const [position, setPosition] = useState({ top: 100, left: 100 });

  async function getDistinctUserCount(tableauId) {
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/count_distinct_users/${tableauId}`);
    return response.data.count;
  }

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/user-count`);
        setUserCount(response.data.user_count);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    const fetchTableaux = async () => {
      console.log("server url :", process.env.REACT_APP_SERVER_URL);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/tableaux`);
      let counts = {};
      try {
        const countsArray = await Promise.all(response.data.map(async (tableau) => {
          const count = await getDistinctUserCount(tableau.idtableau);
          return { idtableau: tableau.idtableau, count };
        }));
        countsArray.forEach(({ idtableau, count }) => {
          counts[idtableau] = count;
        });
      } catch (error) {
        console.error(error);
      }
      const cacheData = {
        tableaux: response.data,
        userCounts: counts,
        timestamp: Date.now()
      };
      localStorage.setItem('tableauxCache', JSON.stringify(cacheData));
    };

    const cachedTableaux = localStorage.getItem('tableauxCache');

    if (cachedTableaux) {
      const { tableaux, userCounts, timestamp } = JSON.parse(cachedTableaux);
      if (Date.now() - timestamp < CACHE_DURATION) {
        console.log('@@@@Using cached tableaux data@@@@');
      } else {
        console.log('@@@@Tableaux cache expired, fetching new data@@@@');
        fetchTableaux();
      }
    } else {
      console.log('@@@@Fetching tableaux data from server@@@@');
      fetchTableaux();
    }

    fetchUserCount();

    const moveElement = () => {
      setPosition(prevPosition => {
        const direction = Math.floor(Math.random() * 4);
        let newTop = prevPosition.top;
        let newLeft = prevPosition.left;

        switch (direction) {
          case 0: // Move up
            newTop -= 20;
            break;
          case 1: // Move down
            newTop += 20;
            break;
          case 2: // Move left
            newLeft -= 20;
            break;
          case 3: // Move right
            newLeft += 20;
            break;
          default:
            break;
        }

        return { top: newTop, left: newLeft };
      });
    };

    const intervalId = setInterval(moveElement, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="text-balance landing-page m-auto text-4xl sm:text-6xl w-full h-full flex gap-6 flex-col items-center justify-center z-0">
      <Badge variant="outline" className="px-1 !pr-2 w-fit text-xl text-green-500 bg-green-100 dark:bg-green-900 dark:text-green-500">
        <i className="fas fa-check-circle mx-0.5 mr-1"></i>
        {t('already')} <span className="mx-1">{userCount}</span> {t('users')}!
      </Badge>
      <p className="mx-auto text-center font-bold w-11/12 sm:w-1/2">
        {t('better_organization')} <span className="text-halo">{t('brilliant_results')}</span>
      </p>
      <p className="mx-auto drop-shadow-md text-center text-lg w-4/5 sm:w-1/3">
        {t('improve_grade_description')}
      </p>
      <Button className="m-2 font-bold" onMouseEnter={handleMouseEnter} onMouseLeave={() => navigate('/search')}>
        {t('browse_tables')}
      </Button>
      <div className="absolute mt-0.5 transition-all duration-500 ease-in-out transform -translate-x-1/2 -translate-y-1/2 text-halo hidden" style={{ top: `${position.top}px`, left: `${position.left}px` }}>
        .
      </div>
    </div>
  );
}

export default LandingPage;